import React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: sticky;
  z-index: 100;
  top: 0;
  > :first-child {
    > :first-child {
      height: 100%;
      padding-left: 50px;
      font-weight: bold;
      line-height: 50px;
      color: inherit;
      text-decoration: none;
    }
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-right: 40px;

    select {
      width: 100px !important;
    }
  }
`

const Header = (props) => {
  return <Wrapper>
    <Box background='primary'>
      <Link to='/'>
        <div>
            NZK Curriculum Dashboard
        </div>
      </Link>
      <div>
        { props.children }
      </div>
    </Box>
  </Wrapper>
}

export default Header
